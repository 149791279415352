.App{
  display: flexbox;
  text-align: center;
  /* color:rgb(61, 172, 172); */
  color: black;
  /* font-family: 'Courier New', Courier, monospace; */
}

/* .App > .nav-button{
  height: 30px;
  width: 20%;
  border: 1px solid black;
  margin: auto;
  border-radius: 20px;
} */

.App > .nav-button > .button1, .button2 {
  height: inherit;
  background-color: white;
  border: 1px solid black;
  /* color:rgb(61, 172, 172); */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  width: 10%;
  font-weight: 800;
}

.button1{
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.button2{
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.button1:hover, .button2:hover{
  transform: scale(1.1);
}

.button1::after, .button2::after{
  background-color: rgb(61, 172, 172);
}