.lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #cef;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }
  

.RobotStatus, .RobotStatuspopup{
    padding-top: 50px;
}


.RobotStatus > table{
    margin: auto;
    /* border: 0.1px solid rgb(226, 207, 207); */
    /* border-spacing: 5px; */
    border-spacing: none;
    border-collapse: collapse;
    box-shadow: 10px 10px 5px #888;
}

.RobotStatuspopup > table{
    margin: auto;
    border-spacing: none;
    border-collapse: collapse;
}


tr {
    line-height: 35px;
    cursor: pointer;
}

tr:hover{
    transform: scale(1.1);
    background-color: rgb(61, 172, 172);
    /* font-size: large; */
}
th{
    border-bottom: 1px solid rgb(226, 207, 207);
    border-top: 0.5px solid #999;
    background-color: #999;
}
td{
    border-bottom: 1px solid rgb(226, 207, 207);
    padding-right: 20px;
}

select{
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    color: inherit;
}