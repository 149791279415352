.configurerobot{
    margin: auto;
    width:35%;
    padding: 10px;
    padding-top: 100px;
    box-sizing: border-box;
}

label{
    display: inline-block;
    /* float: left; */
    clear: left;
    width: 250px;
    text-align: center;
    margin: 10px;
}
input {
  box-shadow:10px 10px 5px rgba(0,0,0,0.06);
  padding:10px;
  margin: 10px;
  display:inline-block;
  width:42%;
  margin:10px 0;
  padding:10px;
  background-color: #fafafa;
  border:0;
  box-shadow:0 0 4px rgba(0,0,0,0.3);
  transition: .3s box-shadow;
}

input:hover{
    box-shadow:0 0 4px rgba(0,0,0,0.5);
}

.configsubmit{
    background: rgb(61, 172, 172);
    color: white;
    border-style: outset;
    border-color: rgb(61, 172, 172);
    height: 40px;
    width: 20%;
    font-family: inherit;
    font-size: large;
    font-weight: 600;
    text-shadow: none;
    transition: .3s box-shadow;
    box-shadow:0 0 4px rgba(0,0,0,0.3);
    cursor: pointer;
}

.configsubmit:hover{
    box-shadow:0 0 4px rgba(0,0,0,0.5);
}
.configsubmit:disabled{
    cursor: not-allowed;
    background-color: rgba(61, 172, 172, 0.508);
    border-color: rgba(61, 172, 172, 0.508);
}